import React from "react";

import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCheckCircle } from "@fortawesome/sharp-regular-svg-icons/faCheckCircle";
import { faFingerprint } from "@fortawesome/sharp-regular-svg-icons/faFingerprint";
import { faLock } from "@fortawesome/sharp-regular-svg-icons/faLock";
import { faShieldCheck } from "@fortawesome/sharp-regular-svg-icons/faShieldCheck";
import { faStopwatch } from "@fortawesome/sharp-regular-svg-icons/faStopwatch";
import { faBolt } from "@fortawesome/sharp-solid-svg-icons/faBolt";
import { faSterlingSign } from "@fortawesome/sharp-solid-svg-icons/faSterlingSign";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";
import { AnimLayerInput } from "../AnimLayerFakeInput";
import { AnimLayerUiRadioButton } from "../AnimLayerUiRadioButton";
import { BankLogos } from "../BankLogos/BankLogos";
import { getTagStyle } from "./styles.css";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton/types";
import type { BankLogosProps } from "../BankLogos/BankLogos";
import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of fontawesome icons
 * ------------------------------------------------------------------------------
 */

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP = {
  faCheckCircle,
  faBolt,
  faBuildingColumns,
  faStopwatch,
  faFingerprint,
  faShieldCheck,
  faLock,
} as const;

export interface AnimLayerListItemProps {
  title: string;
  icon: IconMapKey;
}

export interface AnimLayerInfoDialogProps extends BoxProps, StoryblokBlok {
  amount?: number;
  ctaText?: string;
  description?: string;
  icon?: IconMapKey | "";
  listItems?: Array<AnimLayerListItemProps & StoryblokBlok>;
  tag?: string;
  tagVariant?: "solid";
  title?: string;
  isRadioButtonVisible?: boolean;
  ctaIcon?: unknown;
  text?: string;
  text2?: string;
  bankLogos?: boolean;
  bankLogosNumber?: BankLogosProps["logoNumber"];
  bankLogosPosition?: "left" | "right";
  fakeButton?: AnimLayerFakeButtonProps;
  fakeInput?: boolean;
}

export function AnimLayerInfoDialog({
  className: userClassName,
  isRadioButtonVisible,
  icon = "",
  listItems,
  title,
  ctaText,
  tag,
  tagVariant,
  ctaIcon: __ctaIcon,
  text: text1,
  text2,
  bankLogos = false,
  bankLogosNumber,
  bankLogosPosition = "left",
  fakeButton,
  fakeInput = false,
  ...rest
}: AnimLayerInfoDialogProps) {
  const dynamicIcon = icon ? ICON_MAP[icon] : null;

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        borderRadius="sm"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        height="min-content"
        padding="phoneSpacing2"
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="phoneSpacing1"
        {...rest}
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap="phoneSpacing1"
          width="100%"
        >
          <Box
            alignItems="center"
            // data-motion={ANIMATED.secondary} // TODO
            display="flex"
            justifyContent="space-between"
            gap="phoneSpacing1"
          >
            <ShowChildren when={dynamicIcon}>
              <IconFontAwesome icon={dynamicIcon as IconDefinition} />
            </ShowChildren>

            <ShowChildren when={bankLogos && bankLogosPosition === "left"}>
              <Box
                width="phoneSpacing8"
                display="flex"
                alignItems="center"
                height="auto"
              >
                <BankLogos logoNumber={bankLogosNumber} />
              </Box>
            </ShowChildren>

            <ShowChildren when={title}>
              <Box
                textAppearance="phone_text_lg"
                marginRight="auto"
                fontWeight="bold"
              >
                {title}
              </Box>
            </ShowChildren>
            <ShowChildren when={bankLogos && bankLogosPosition === "right"}>
              <Box
                width="phoneSpacing8"
                display="flex"
                alignItems="center"
                height="auto"
              >
                <BankLogos logoNumber={4} />
              </Box>
            </ShowChildren>
            <ShowChildren when={isRadioButtonVisible}>
              <AnimLayerUiRadioButton isChecked />
            </ShowChildren>

            <ShowChildren when={tag}>
              <Box
                className={getTagStyle({ appearance: tagVariant })}
                textAppearance="phone_text_xs"
              >
                {tag}
              </Box>
            </ShowChildren>
          </Box>

          <Box
          // data-motion={ANIMATED.secondary} // TODO
          >
            <ShowChildren when={text1}>
              <StoryblokRichText textAppearance="phone_text_md" text={text1!} />
            </ShowChildren>

            <ShowChildren when={fakeInput}>
              <AnimLayerInput
                marginY="phoneSpacing1"
                icon={faSterlingSign as IconDefinition}
                title="10"
              />
              <Box marginY="phoneSpacing1" display="flex" gap="phoneSpacing0">
                <AnimLayerInput
                  status="selected"
                  appearance="pill"
                  title="£10"
                />
                <AnimLayerInput appearance="pill" title="£20" />
                <AnimLayerInput appearance="pill" title="£50" />
                <AnimLayerInput appearance="pill" title="£100" />
              </Box>
            </ShowChildren>

            {Array.isArray(listItems) &&
              listItems.map((item) => {
                const dynamicItemIcon = ICON_MAP[item.icon];

                return (
                  <StoryblokEditable key={item._uid} {...item}>
                    <Box
                      key={item._uid}
                      alignItems="center"
                      color="text_lowContrast"
                      display="flex"
                      gap="phoneSpacing1"
                      marginBottom="phoneSpacing1"
                      textAppearance="phone_text_sm"
                    >
                      {dynamicItemIcon && (
                        <IconFontAwesome
                          icon={dynamicItemIcon as IconDefinition}
                        />
                      )}
                      {item.title && <Box>{item.title}</Box>}
                    </Box>
                  </StoryblokEditable>
                );
              })}
            <ShowChildren when={text2}>
              <StoryblokRichText
                marginY="phoneSpacing3"
                alignItems="center"
                textAppearance="phone_text_md"
                text={text2!}
              />
            </ShowChildren>
          </Box>
          <ShowChildren when={ctaText || fakeButton?.variant === "paybybank"}>
            <AnimLayerFakeButton
              data-motion={ANIMATED.secondary}
              {...fakeButton}
            >
              {ctaText}
            </AnimLayerFakeButton>
          </ShowChildren>
        </Box>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
